<template>
  <div v-if="emailTemplate">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.emailTemplateUpdate')"
      :title="$t('messages.emailTemplateUpdate')"
      @submitPressed="submitPressed"
    />
    <div class="content-box">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group
              :class="{row}"
              :label="$t('forms.notificationEmails')"
              label-for="notificationEmails"
              label-cols-md="2"
              content-cols-lg="4"
            >
              <v-select
                id="notificationEmails"
                v-model="emailTemplate.notificationEmails"
                taggable
                multiple
                push-tags
              />
            </b-form-group>
            <b-form-group
              class="row"
              :label="$t('forms.owner')"
              label-for="h-owner"
              label-cols-md="2"
              content-cols-lg="4"
            >
              <b-form-checkbox v-model="emailTemplate.owner" id="h-owner" switch/>
            </b-form-group>
            <b-form-group
              class="row"
              :label="$t('forms.enabled')"
              label-for="h-enabled"
              label-cols-md="2"
              content-cols-lg="4"
            >
              <b-form-checkbox v-model="emailTemplate.onOff" id="h-enabled" switch/>
            </b-form-group>
            <b-form-group>
              <b-button v-b-toggle.collapse-1 variant="primary">{{ $t('forms.placeholders') }}</b-button>
              <b-collapse id="collapse-1" class="mt-2">
                <b-card>
                  <div class="vs__selected-options" v-for="placeholder in emailTemplate.placeholders"
                       :key="placeholder">
                    <span class="vs__selected">{{ placeholder.name }}</span>
                    <span style="margin-top: 5px; margin-left: 3px;"> - {{ placeholder.description }}</span>
                  </div>
                </b-card>
              </b-collapse>
            </b-form-group>
            <b-form-group
              :class="{ row, error: v$.emailTemplate.subject.$errors.length }"
              :label="$t('forms.subject')"
            >
              <b-form-input
                v-model="emailTemplate.subject"
                id="h-subject"
                type="text"
              />
              <div
                class="input-errors"
                v-for="error of v$.emailTemplate.subject.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>
            <b-form-group
              :class="{ row, error: v$.emailTemplate.message.$errors.length }"
              :label="$t('forms.message')" id="input-group-2" class="mb-0">
              <tinymce
                :content="emailTemplate.message"
                @editorContentChanged="updateContent"
              />
              <div
                class="input-errors"
                v-for="error of v$.emailTemplate.message.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import vSelect from "vue-select";
import tinymce from "@/components/tinymce.vue";

export default {
  components: {
    TitleBar,
    vSelect,
    tinymce,
  },
  mixins: [ResourceUtils, ListUtils],
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      emailTemplate: {
        subject: {required},
        message: {required},
      },
    };
  },
  data() {
    return {
      emailTemplate: null,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      const id = this.$route.params.id;
      this.$EmailTemplates.getResource({id}).then((response) => {
        this.emailTemplate = response.data;
      });
    },
    updateContent(content) {
      this.emailTemplate.message = content;
    },
    async submitPressed() {
      const isValid = await this.v$.$validate();
      this.$emit("clearAction");
      if (isValid) {
        const body = JSON.parse(JSON.stringify(this.emailTemplate));
        this.update(this.$EmailTemplates, body.id, body, this.$t("messages.emailTemplateUpdated"), null, null, this.error);
      }
    },
  },
}
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
