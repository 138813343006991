<template>
  <editor
    v-model="editorContent"
    api-key="6iyf5pv4clnbkp8bablx3cghk9mssk1fbsf39ccdh6gb72b5"
    initial-value=""
    :init="mceInit"
    @change="handlerFunction"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    Editor,
  },
  props: {
    content: { type: String, default: '' },
    objectType: { type: String, default: '' },
    options: { type: Object, default: () => {} }
  },
  data() {
    return {
      editorContent: this.content, 
      mceInit: {
        language: 'en',
        height: this.options && this.options.height ? this.options.height : 500,
        menubar: false,
        entity_encoding: 'raw',
        entities: '160,nbsp,38,amp,60,lt,62,gt',
        cleanup: true,
        paste_auto_cleanup_on_paste: true,
        paste_postprocess(pl, o) {
          // eslint-disable-next-line no-param-reassign
          o.node.innerHTML = o.node.innerHTML.replace(/&nbsp;/gi, ' ')
        },
        plugins: [
          'advlist autolink lists link image charmap',
          'searchreplace visualblocks code fullscreen',
          'print preview anchor insertdatetime media',
          'paste code help wordcount table',
        ],
        toolbar:
          'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | link image | table | code | help',
        images_upload_url: 'postAcceptor.php',
        images_upload_handler: this.fileUpload,
        images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg',
      },
    }
  },
  methods: {
    fileUpload(blobInfo, success, failure) {
      const formdata = new FormData()
      formdata.append('file', blobInfo.blob())
      

      this.$Files.createResource({ body: formdata })
        .then(response => {
          if (response.status === 201) {
            success(`${response.data.url}`)
          } else {
            failure(this.$t('messages.fileFailure'))
          }
        })
        .catch(() => {
          failure(this.$t('messages.fileFailure'))
        })
    },
    handlerFunction() {
      this.$emit('editorContentChanged', this.editorContent)
    },
  },
}
</script>

<style lang="scss">
.tox-tinymce-aux {
  z-index: 50000 !important;
}
</style>
