import enumsEn from '@/locales/enum-en.json'
import enumsSk from '@/locales/enum-sk.json'

export default {
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    getEnumList(names, ...lists) {
      let enums = enumsEn
      if (this.$i18n.locale === 'sk') {
        enums = enumsSk
      }
      if (!(names instanceof Array)) {
        names = [names]
      }
      this.$Enums.getCollection({params: {name: names, cache: true, ttl: 86400}})
        .then(response => {
          names.forEach((name, index) => {
            response.data['hydra:member'].forEach(element => {
              if (name === element.name) {
                element.keys.forEach(key => {
                  lists[index].push({
                    label: enums[name][key],
                    id: key,
                  })
                })
              }
            })
          })
        })
    },
    getUserList(args, ...lists) {
      const {params} = args
      params.pagination = false
      if (!(params.role instanceof Array)) {
        params.role = [params.role]
      }
      this.$Users.getCollection({params}, 'user_list')
        .then(response => {
          response.data['hydra:member'].forEach(element => {
            params.role.forEach((role, index) => {
              if (role === element.role) {
                lists[index].push({
                  label: `${element.givenName} ${element.familyName}`,
                  id: element['@id'],
                  numberId: element.id,
                })
              }
            })
          })
        })
    },
    getPageList(list, exclude = null) {
      const params = {
        pagination: false
      }
      this.$WebPages.getCollection({params}, 'id_name')
        .then(response => {
          response.data['hydra:member'].forEach(element => {
            if(exclude !== element.id) {
              list.push({
                label: `${element.name}`,
                id: element['@id'],
                numberId: element.id,
              })
            }

          })
        })
    },
    getResortList(list) {
      const params = {
        pagination: false
      }
      this.$Resorts.getCollection({params}, 'id_name')
        .then(response => {
          response.data['hydra:member'].forEach(element => {
            list.push({
              label: `${element.name}`,
              id: element['@id'],
              numberId: element.id,
            })
          })
        })
    },
    getList(resource, list, serializationGroup, exclude = null) {
      return new Promise(resolve => {
        const params = {
          pagination: false
        }
        resource.getCollection({params}, serializationGroup)
          .then(response => {
            response.data['hydra:member'].forEach(element => {
              if (exclude !== element.id) {
                list.push({
                  label: `${element.name}`,
                  id: element['@id'],
                  numberId: element.id,
                })
              }
            })
            resolve(list)
          })
      })
    },
    getVariablesList(list, exclude = null) {
      return this.getList(this.$Variables, list, 'id_name', exclude)
    },
  },
}
